// 岗位晋升培训接口
import myaxios from "../MyAxios";
import baseURL from "../baseURL";
const BMDURL = baseURL.BMDURL;

const PxApi = {
  // 培训基本信息接口 --- 添加
  addTrainInfo({ title, type, remark }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/traininfoapi/save&title=${title}&opmode=add&type=${type}&remark=${remark}`,
      ""
    );
  },
  // 培训基本信息接口 --- 修改
  editTrainInfo({ id, title, type, remark }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/traininfoapi/save&id=${id}&title=${title}&opmode=edit&type=${type}&remark=${remark}`,
      ""
    );
  },
  // 培训基本信息接口 --- 删除
  delTrainInfo({ id }) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/traininfoapi/del&id=${id}`,
      ""
    );
  },
  // 培训基本信息接口 --- 查询所有
  getTrainList() {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/traininfoapi/getlist`,
      "",
      false
    );
  },
  // 培训基本信息接口 --- 查询某一个
  getTrainInfo_id({ eq_id }) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/traininfoapi/getlist&eq_id=${eq_id}`,
      ""
    );
  },
  // 培训主题名称接口 --- 查询
  getTrainInfo_topic({ eq_tid }) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/traintopicsapi/getlist&eq_tid=${eq_tid}`,
      "",
      false
    );
  },
  // 培训主题接口 --- 添加
  // https://www.zhongqilinghui.com/oa/index.php?s=/traintopicsapi/save&tid=培训id
  // &opmode=add&topicname=财务&sort=排序数
  addTrainInfo_topic({ tid, topicname, sort }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/traintopicsapi/save&tid=${tid}&opmode=add&topicname=${topicname}&sort=${sort}`,
      ""
    );
  },
  // 培训主题接口 --- 编辑
  editTrainInfo_topic({ id, topicname, sort }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/traintopicsapi/save&id=${id}&opmode=edit&topicname=${topicname}&sort=${sort}`,
      ""
    );
  },
  // 培训主题接口 --- 删除
  delTrainInfo_topic({ id }) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/traintopicsapi/del&id=${id}`,
      ""
    );
  },
  // 培训主题内容接口 --- 查询
  // https://www.zhongqilinghui.com/oa/index.php?s=/traindetailapi/getlist&eq_tid=培训id&eq_topicid=主题id
  // userid不传默认也是当前用户id
  // 只传eq_tid可以查到所有的
  getTrainDetail_topic({ eq_tid, eq_topicid = "", showLoading, userid = "" }) {
    // console.log(eq_tid, eq_topicid, showLoading);
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/traindetailapi/getlist&eq_tid=${eq_tid}&eq_topicid=${eq_topicid}&userid=${userid}`,
      "",
      showLoading
    );
  },
  // 培训主题内容接口 --- 查询 某一个
  getTrainContent({ eq_tid, eq_topicid, eq_id }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/traindetailapi/getlist&eq_tid=${eq_tid}&eq_topicid=${eq_topicid}&eq_id=${eq_id}`,
      ""
    );
  },
  // 培训主题内容接口 --- 查询 所有内容
  getTrainDetail_topic_all({ eq_tid }) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/traindetailapi/getlist&eq_tid=${eq_tid}`,
      "",
      false
    );
  },
  // 培训主题内容接口 --- 删除
  delTrainDetail_topic({ id }) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/traindetailapi/del&id=${id}`,
      ""
    );
  },
  // 培训主题内容接口 --- 添加
  // https://www.zhongqilinghui.com/oa/index.php?s=/traindetailapi/save&tid=培训id
  // &opmode=add&topicid=主题id&sort=排序数&cname=内容名称&previd=前序内容id&video=视频上传路径&addfile=文件上传路径&vlength=视频长度&flength=文件长度
  addTrainDetail_topic({
    tid,
    topicid,
    sort,
    cname,
    video,
    addfile,
    vlength,
    flength,
  }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/traindetailapi/save&tid=${tid}&opmode=add&topicid=${topicid}&sort=${sort}&cname=${cname}&video=${video}&addfile=${addfile}&vlength=${vlength}&flength=${flength}`,
      ""
    );
  },
  // 培训主题内容接口 --- 编辑 id=内容id
  editTrainDetail_topic({
    id,
    tid,
    topicid,
    sort,
    cname,
    video,
    addfile,
    vlength,
    flength,
  }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/traindetailapi/save&id=${id}&tid=${tid}&opmode=edit&topicid=${topicid}&sort=${sort}&cname=${cname}&video=${video}&addfile=${addfile}&vlength=${vlength}&flength=${flength}`,
      ""
    );
  },
  // 学习过程日志 --- 查询
  // &eq_tid=培训id&eq_topicid=主题id&eq_detailid=内容id
  getTrainLog({ eq_tid, eq_topicid, eq_detailid }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/trainlogapi/getlist&eq_tid=${eq_tid}&eq_topicid=${eq_topicid}&eq_detailid=${eq_detailid}`,
      ""
    );
  },
  // // 学习过程日志 --- 查询所有
  // getTrainLogList({ page = 1, limit = 10 }) {
  //   return myaxios.get(
  //     BMDURL + `/oa/index.php?s=/trainlogapi/getsumlist`,
  //     { page, limit },
  //     false
  //   );
  // },
  // 学习过程日志 --- 添加
  // https://www.zhongqilinghui.com/oa/index.php?s=/trainlogapi/save&tid=培训id
  // &opmode=add&topicid=主题id&detailid=内容id&starttime=开始时间&endtime=结束时间&evtlength=时长（秒）&evtcontent =1视频2阅读
  addTrainLog({
    tid,
    topicid,
    detailid,
    starttime,
    endtime,
    evtlength,
    evtcontent,
  }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/trainlogapi/save&tid=${tid}&opmode=add&topicid=${topicid}&detailid=${detailid}&starttime=${starttime}&endtime=${endtime}&evtlength=${evtlength}&evtcontent=${evtcontent}`,
      ""
    );
  },
  // 学习状态 --- 添加
  //  https://www.zhongqilinghui.com/oa/index.php?s=/trainstatusapi/save&tid=培训id
  //  &opmode=add&topicid=主题id&detailid=内容id &status=0未完成 1完成
  addTrainStatus({ tid, topicid, detailid, status }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/trainstatusapi/save&tid=${tid}&opmode=add&topicid=${topicid}&detailid=${detailid}&status=${status}`,
      ""
    );
  },
  // 学习状态 --- 修改 &id=状态记录id&opmode=edit&status=0未完成 1完成
  editTrainStatus({ id, status }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/trainstatusapi/save&id=${id}&opmode=edit&status=${status}`,
      ""
    );
  },
  // 学习状态 --- 查询
  // &eq_tid=培训id&eq_topicid=主题id&eq_detailid=内容id
  getTrainStatus({ eq_tid, eq_topicid, eq_detailid, showLoading }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/trainstatusapi/getlist&eq_tid=${eq_tid}&eq_topicid=${eq_topicid}&eq_detailid=${eq_detailid}`,
      "",
      showLoading
    );
  },
  // 学习状态 --- 获取某人某个内容的学习状态的接口 里面的status=1为已完成
  getStatus_user({ eq_tid, eq_topicid, eq_detailid, userid = "" }) {
    return myaxios.get(
      BMDURL +
        `/oa/index.php?s=/trainstatusapi/getstatus&tid=${eq_tid}&topicid=${eq_topicid}&detailid=${eq_detailid}&userid=${userid}`,
      "",
      false
    );
  },
  // 学习 --- 查询 该用户 所有
  getTrainLog_user({ userid }) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/trainlogapi/getlist&userid=${userid}`,
      ""
    );
  },

  // 培训统计页--日志查询
  getTrainLogList(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/trainlogapi/getsumlist`,
      params,
      false
    );
  },
  // 培训统计页--查询某个情况
  getTrainList_id(params) {
    return myaxios.get(
      BMDURL + `/oa/index.php?s=/trainstatusapi/getsumlist`,
      params,
      false
    );
  },
};

export default PxApi;
